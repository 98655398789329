import React from 'react'
import { Link } from 'gatsby'

import RichTextRenderer from '../components/rich-text-renderer'

//import styles from './article-preview.module.css'
function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g,'-')
        .replace(/ +/g,'-')
        .replace(/-+/g,'-')
        ;
}


class CollapsibleElement extends React.Component {

  render() {
    const step = this.props.step;
    const numbered = this.props.numbered;
    //const iterator = this.props.iterator;

    var description = '';
    var ariaExpanded = 'false'
    var headerClass = 'collapsed'
    var panelClass = 'panel-collapse collapse'
    var panelId = false

    var title = ''

    var collapsibleId = ''

    var stepContent = ''

    var slug = '';

    if(this.props.step){
      title = step.title;
    }
    if(!this.props.step && this.props.title){
      title = this.props.title;
    }
    slug = convertToSlug(title);



    if(this.props.step) {
      collapsibleId = step.id.replace(/[- )(]/g,'')
    }
    if(!this.props.step && this.props.stepId){
      collapsibleId = this.props.stepId;
      slug = slug + '-' + collapsibleId.substring(0,2);
    }



    //content
    if(this.props.step) {
      stepContent = step.stepContent.json
    }
    if(!this.props.step && this.props.stepContent ){
      stepContent = this.props.stepContent
    }

    if (!this.props.iterator && this.props.iterator != 0) {
      var iterator = '';
    }
    else {
      var iterator = this.props.iterator;
      panelId = 'step-'+(iterator+1)

    }

    if(iterator == 0 && numbered == 'numbered') {
      ariaExpanded = 'true';
      headerClass = '';
      panelClass = 'panel-collapse collapse in'
    }

        //console.log(slug)

    return (
      <div className={"panel panel-default"} id={panelId}>
    <div className={"panel-heading"} role="tab" id={'heading' + slug}>
      <h3 className={"panel-title"}>
        <a className={headerClass} role="button" data-toggle="collapse" data-parent="#accordion" href={'#' + slug} aria-expanded={ariaExpanded} aria-controls={slug}>
          {title}
        </a>
      </h3>
    </div>
    <div className={panelClass} id={slug} aria-expanded={ariaExpanded} role="tabpanel" aria-labelledby={'heading' + slug}>
    <h3 className={"sr-only"}>{title}</h3>
    <RichTextRenderer containerClass={"panelBody"} articleJSON={stepContent} />
    </div>
  </div>
    )
  }
}

export default CollapsibleElement
