import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import styles from './rich-text.module.scss';

// Gatsby adds 'c' to entity id if it starts with a number.

function fixId(id) {
  if (id.startsWith("c")) {
    return id.slice(1)
  }
  else return id
}

export const ContentfulFixedAsset = ({ contentfulId }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulAsset {
        edges {
          node {
            fluid(maxWidth: 840, quality: 90) {
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
            contentful_id
            description
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `)

  const image = data.allContentfulAsset.edges.find(
    edge => edge.node.contentful_id === fixId(contentfulId)
  )

  if(image.node.file.url.substr(image.node.file.url.length - 3) == 'svg') {
    return <figure><img src={image.node.file.url} width={image.node.file.details.image.width} height={image.node.file.details.image.height}/></figure>
  }
  else {
    var imageClass = ""
    var altText = ""

    if(image.node.description){
      altText = image.node.description
      if(styles[altText]) {

        imageClass = styles[altText]
       // console.log(imageClass)
        altText = image.node.title
      }
    }
    //console.log(image.node.fluid);
    
    return <Img style={{maxWidth: image.node.file.details.image.width}} className={imageClass} itemProp={`image`} alt={altText} fluid={image.node.fluid} width={image.node.file.details.image.width} Tag={'figure'}/>
  }

  
}
export default ContentfulFixedAsset