import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import Feedback from '../components/feedback'

import Collapsible from '../components/collapsible-element'

import LeadSection from '../components/lead-section'








class PageDefault extends React.Component {
  render() {

    const post = get(this.props, 'data.contentfulSupportSiteProduct')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    
    var numbered;


    return (
      <Layout location={this.props.location}>
        <section>
          <Helmet title={`${post.title} | ${siteTitle}`} />
                    <LeadSection  title={post.title}/>
        </section>
      </Layout>
    )
  }
}

export default PageDefault

export const pageQuery = graphql`
  query PageInformation($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSupportSiteProduct(slug: { eq: $slug }) {
      title
      slug
    }
  }
`
