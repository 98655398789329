import React from 'react'
import Link from 'gatsby-link'
import { OutboundLink } from 'gatsby-plugin-google-analytics'


import styles from './feedback.module.scss'
import { button } from  './new-button.module.scss'
class Feedback extends React.Component {
  constructor(props) {
    super(props);
   // Bind the this context to the handler function
        // Set some state
        this.state = {
       positive: false,
       negative: false,
       formSent: false
     }
  }

  showAll = () => {
    this.setState({
      positive: true
    });
    if(process.env.NODE_ENV !== 'development') {
      ga('send', 'event', 'Feedback', 'Helpful', this.props.post.title);
    }
    
  }
  showNone = () => {
    this.setState({
      negative: true
    });
    if(process.env.NODE_ENV !== 'development') {
      ga('send', 'event', 'Feedback', 'Not helpful', this.props.post.title);
    }

  }

  cancelNegative = () => {
    this.setState({
      negative: false
    });
  }

  formSend = () => {
    this.setState({
      formSent: true
    });
  }

  render() {

    var panelClass = '';
    var errorMessage = "Thank you!"



    if (this.state.positive) {
      panelClass = styles.thumbsUp
      errorMessage = "Thank you!"
    }

    if (this.state.negative) {
      panelClass = styles.thumbsDown
      errorMessage = "Thanks for your feedback"
    }

    if (this.state.formSent) {
      panelClass = [styles.thumbsDown, styles.formSent].join(' ')
      errorMessage = "Thanks for your feedback"
    }

    const articleInformation = this.props.post;
    var resultsLink = '';
    if(articleInformation.productReference != null) {
      resultsLink = <Link className={button} to={`/${articleInformation.productReference.slug}/${articleInformation.articleCategoryTopic.slug}/`}>See all {articleInformation.articleCategoryTopic.title} results</Link>
    }

    return (
  <aside className={[styles.feedback, panelClass].join(' ')}>
  {resultsLink}
  </aside>
    )
  }
}

export default Feedback
