import React from 'react'
import { Link } from 'gatsby'

import styles from './lead-section.module.scss'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import RichTextRenderer from '../components/rich-text-renderer'





class LeadSection extends React.Component {
  render() {
    // const title = this.props.title;
    // const eyebrow = this.props.eyebrow;
    // const subheading = this.props.subheading;

    var title = '';
    var eyebrow = '';
    var subheading = '';
    var description = '';

    var titleLength = '';

    var largeArticle = false;
    var largeArticleMessage = 'NOT LARGE'


    var descriptionClass = styles.opening

    var containerClass = 'leadSection'

    if(this.props.title){
      //title = this.props.title;
      let titleArray = this.props.title.split(" ");
      if(titleArray.length > 3) {
        for (var i = 0; i < titleArray.length; i++) {
          let spacer = " "
          if(i == titleArray.length-2){
            spacer = "\u00A0"
          }
          else {
            spacer = " " 
          }
          title += titleArray[i]+spacer
        }  
      }
      else {
        title = this.props.title
      }
      

      //console.log(titleArray)

      title = <h1 itemProp={"headline name"} className={styles.sectionHeader}>{title}</h1>
    }
    if(this.props.largeArticle){
      largeArticle = true;
      largeArticleMessage = 'it\'s a large article!' 
      descriptionClass = [styles.opening, styles.large].join(' ')
    }
    if(this.props.eyebrow && this.props.eyebrow != ''){
      eyebrow = <h2 itemProp={"articleSection"} className={styles.eyebrow}>{this.props.eyebrow}</h2>
;
    }
    if(this.props.subheading && this.props.subheading != ''){
      subheading = <div itemProp={"description"} className={descriptionClass}
    dangerouslySetInnerHTML={{
      __html: this.props.subheading,
    }}
  />
    }

    if(this.props.description){
      description = this.props.description;
    }


return (
<div className={styles.leadSection}>
 
  {title}
   {eyebrow}
  <div itemProp="author" itemScope={""} itemType="http://schema.org/Organization"><meta itemProp="name" content="YouView"/></div>
  {subheading}
  <RichTextRenderer containerClass={containerClass} articleJSON={description} />

</div>
    
)
  }
}

export default LeadSection
