import React from 'react';
import { Link } from 'gatsby';


import { OutboundLink } from 'gatsby-plugin-google-analytics';



import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import FixedAsset from '../components/contentful-fixed-asset'
import Collapsible from '../components/collapsible-element'



import styles from './rich-text.module.scss';


class EmbeddedImage extends React.Component { 
  render(){
    const fields = this.props.fields
    //console.log(fields);
    var imageClass = ''
    if (!this.props.fields) {
       return null;
    }
    

    const sys = this.props.sys
    var imageClass = ''
    if (!this.props.fields) {
       return null;
    }
    var altText = fields.title[`en-US`]
    
    if(this.props.fields && this.props.fields.description) {
      altText = fields.description[`en-US`]
      if(styles[altText]) {
        imageClass = styles[altText]
        altText = fields.title[`en-US`]
      }
    }

    if(this.props.sys) {
      return <FixedAsset contentfulId={this.props.sys.id} />
    }


    return(
       <React.Fragment>
       <figure itemProp={`image`} className={imageClass} itemScope={true} itemtype={`https://schema.org/ImageObject`}><img className={styles.imgResponsive} width={fields.file[`en-US`].details.image.width} height={fields.file[`en-US`].details.image.height} src={fields.file[`en-US`].url} itemProp={'contentUrl url'} alt={altText}/></figure> 
        </React.Fragment>
      )
  }
}
class InlineEntry extends React.Component {
  render() {
    const fields = this.props.fields
    let icon = this.props.fields.icon[`en-US`].fields.file[`en-US`]
    const content = this.props.content
    var linkReact = <img width={icon.details.image.width} height={icon.details.image.height} src={icon.url} alt={content}/>

    return (
      
      <span className={styles.inlineImage}> {linkReact} </span>
      
      )
  }
}


class EntryHyperlink extends React.Component {
  render() {
    const fields = this.props.fields
    const content = this.props.content

    
    var pageSlug = '';
    var linkTitle = ''

    if(this.props.content) {
      linkTitle = this.props.content.value
    }
    else {
      linkTitle = fields.title[`en-US`]
    }
    if(this.props.fields) {
      if(fields.productReference) {
        pageSlug += `/${fields.productReference['en-US'].fields.slug['en-US']}`;
      }

      if(fields.articleCategoryTopic) {
        pageSlug += `/${fields.articleCategoryTopic['en-US'].fields.slug['en-US']}`;
      }
      if(fields.pageTopic) {
        pageSlug += `/${fields.pageTopic['en-US'].fields.slug['en-US']}`;
      }
      
      if(fields.slug) {
        pageSlug += `/${fields.slug['en-US']}/`;
      }
    }
    var linkReact = <Link to={pageSlug}>{linkTitle}</Link>

    return ( linkReact
      
      )
  }
}

class RegularHyperlink extends React.Component {
  render() {
    const fields = this.props.fields.uri
    const content = this.props.content

    const linkMatchValue = "https://support.youview.com"
    

    var n = fields.startsWith("https://support.youview.com");

    var linkReact = ''

      //console.log("n is "+n)


    if(fields.startsWith("https://support.youview.com")) {
      var newLink = fields.substr(linkMatchValue.length)
      linkReact = <Link to={newLink}>{content}</Link>
      
    }
    else {
      linkReact = <OutboundLink href={fields}>{content}</OutboundLink>
    }
    return ( linkReact )
  }
}


class Paragraph extends React.Component {
  render() {
    const fields = this.props.children 
    
    if(!this.props.children) {
      return null    
    }
    
    var paragraph = <p>{fields}</p>

    return ( 
      paragraph
      )
  }
}







class EmbeddedEntry extends React.Component {
  render() {
    const fields = this.props.fields
    
    var title = ''
    var content = ''
    if(fields.title) {
      //console.log("embedded entry title is "+fields.title['en-US']);
      title = fields.title['en-US']
    }
    if(fields.stepContent) {
      content = fields.stepContent['en-US'] 
    }
      
    


    return (
      
      <span> <h4>{title}</h4>{documentToReactComponents(content)}</span>
      
    )
      }
    }

class RichTextRenderer extends React.Component {
  render() {
    const articleJSON = this.props.articleJSON;
    var emptyArticle = '';
    if(articleJSON == '' || !this.props.articleJSON) {
      return null;
    }
    var containerClass = styles.article
    var itemProp = false
    var itemPropElement = ''
    if(this.props.containerClass) {
      containerClass = this.props.containerClass
      if(styles[containerClass]){
        containerClass = styles[containerClass]
      }
      containerClass = [styles.article, containerClass].join(' ')
    }
    if(this.props.itemProp) {
      itemPropElement = this.props.itemProp
      itemProp = true
    } 

      const options = {
       renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const fields = node.data.target.fields;
             const sys = node.data.target.sys;
             return <EmbeddedImage sys={sys} fields={fields}/>

      },
      [INLINES.EMBEDDED_ENTRY]: (node) => {
        const fields = node.data.target.fields;
        const content = node.data.target.sys.contentType.sys.id;
        if(content == 'supportSiteInlineImage') {
          return <InlineEntry content={content} fields={fields}/>
        }
        else {
          return null
        }
        //return fields.id
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const fields = node.data.target.fields;

        //return fields.id
        if(node.data.target.sys.contentType.sys.id == 'supportSiteArticleStep') {
          return <div className={'panel-group support no-padding'}><Collapsible title={node.data.target.fields.title['en-US']} stepId={node.data.target.sys.id} stepContent={node.data.target.fields.stepContent['en-US'] }/></div>
        }
        else {
          return <EmbeddedEntry fields={fields}/>
        }

      },
      [INLINES.ENTRY_HYPERLINK]: (node) => {
        const fields = node.data.target.fields;
        const content = node.content[0];
        if(node.data.target.sys.contentType != null) {
          //console.log(node.data.target.sys.contentType)  
        }
        else {
          //console.log("no contentType!")
        }
        var nodeType = ''
        
        if (typeof(node.data.target.sys.contentType) !== 'undefined') {
          nodeType = node.data.target.sys.contentType.sys.id
        }
        if(nodeType == 'supportSiteArticle' || nodeType == 'supportSitePage') {
          return <EntryHyperlink content={content} fields={fields}/>
        }
        else {
          return null
        }
      },
      [INLINES.HYPERLINK]: (node) => {
        const fields = node;
        const content = node.content[0].value
          return <RegularHyperlink content={content} fields={node.data}/>
      },
      [BLOCKS.LIST_ITEM]: (node, children) => {
        //console.log(node)
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
            [INLINES.ENTRY_HYPERLINK]: (node) => {
              const fields = node.data.target.fields;
              const content = node.content[0];
              if(node.data.target.sys.contentType != null) {
                //console.log(node.data.target.sys.contentType)  
              }
              else {
                //console.log("no contentType!")
              }
              var nodeType = ''
              
              if (typeof(node.data.target.sys.contentType) !== 'undefined') {
                nodeType = node.data.target.sys.contentType.sys.id
              }
              if(nodeType == 'supportSiteArticle' || nodeType == 'supportSitePage') {
                return <EntryHyperlink content={content} fields={fields}/>
              }
              else {
                return null
              }
            },
            [INLINES.HYPERLINK]: (node) => {
              const fields = node;
              const content = node.content[0].value
              //console.log(node.content[0]);
                return <RegularHyperlink content={content} fields={node.data}/>
            },
            [INLINES.EMBEDDED_ENTRY]: (node) => {
              const fields = node.data.target.fields;
              //const content = node.data.target.fields;
              // console.log('inline')
              // console.log(node);
              const content = node.data.target.sys.contentType.sys.id;
              if(content == 'supportSiteInlineImage') {
                return <InlineEntry content={content} fields={fields}/>
              }
              else {
                return null
              }
              //return fields.id
            },
          },
        })
      
        return (
          <li>
            {UnTaggedChildren}
          </li>
        )
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
         //if(typeof node.parent !== 'undefined'){
           //console.log(node)
         //}
        if (node.content[0].value === '' && node.content[0].marks.length == 0 && node.content.length === 1) {
        //return <br class={'inserted'}/>
        return null
        } else {
          return <Paragraph>{children}</Paragraph> 
        }
        
        
        
      },

    },
    
    renderText: text => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };



  //console.log(articleJSON)

    
// console.log('itemProp')
// console.log(itemProp)

return (
  
    <div itemProp={itemProp ?
    itemPropElement : 
    undefined} className={containerClass}>
{documentToReactComponents(articleJSON, options)}              
    </div>
)
  }
}

export default RichTextRenderer
